import type React from "react";
import { Card, CardContent, CardHeader } from "./ui/card";
import { Copy } from "lucide-react";
import { Button } from "./ui/button";
import { useEffect, useState } from "react";
import { toast } from "sonner";

interface User {
	id: string;
	name: string;
	wallet: string;
	role: string;
	apiKey: string;
	createdAt: Date;
	updatedAt: Date;
	priceFor5Min: number;
	priceFor15Min: number;
	priceFor1H: number;
}

const formatDate = (date: Date) =>
	date.toISOString().split(".")[0].replace("T", " ");

interface AdminUserTableProps {
	users: User[];
}

export const AdminUserGrid: React.FC<AdminUserTableProps> = ({ users }) => {
	const [copiedId, setCopiedId] = useState<string | null>(null);

	const handleCopyApiKey = async (apiKey: string, userId: string) => {
		await navigator.clipboard.writeText(apiKey);
		setCopiedId(userId);
		setTimeout(() => setCopiedId(null), 2000);
		toast.success("API Key copied to clipboard");
	};

	return (
		<div className="grid grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-4">
			{users?.map((user) => (
				<Card
					key={user.id}
					className="hover:shadow-lg transition-shadow duration-200"
				>
					<CardHeader className="space-y-1 border-b-secondary border-b-2">
						<div className="flex flex-col mb-3">
							<h2 className="text-xl font-semibold tracking-tight truncate">
								{user.name}
							</h2>
							<span className="text-sm text-muted-foreground truncate">
								{user.wallet}
							</span>
						</div>
						<div className="flex flex-row justify-around items-center mt-2 text-sm">
							<div className="text-center">
								<span className="block text-muted-foreground">5min</span>
								<span className="font-medium">{user.priceFor5Min}</span>
							</div>
							<div className="text-center">
								<span className="block text-muted-foreground">15min</span>
								<span className="font-medium">{user.priceFor15Min}</span>
							</div>
							<div className="text-center">
								<span className="block text-muted-foreground">1h</span>
								<span className="font-medium">{user.priceFor1H}</span>
							</div>
						</div>
					</CardHeader>
					<CardContent className="space-y-2">
						<div className="flex justify-between items-center">
							<span className="text-sm text-muted-foreground">Id:</span>
							<span className="text-sm font-medium truncate max-w-[180px]">
								{user.id}
							</span>
						</div>
						<div className="flex justify-between items-center">
							<span className="text-sm text-muted-foreground">Role:</span>
							<span className="text-sm font-medium">{user.role}</span>
						</div>
						<div className="flex justify-between items-center">
							<span className="text-sm text-muted-foreground">Created:</span>
							<span className="text-sm">{formatDate(user.createdAt)}</span>
						</div>
						<div className="flex justify-between items-center">
							<span className="text-sm text-muted-foreground">Updated:</span>
							<span className="text-sm">{formatDate(user.updatedAt)}</span>
						</div>
						<div className="flex justify-between items-center">
							<span className="text-sm text-muted-foreground">API Key:</span>
							<div className="flex items-center gap-2">
								<span className="text-sm font-medium">{"*".repeat(20)}</span>
								<Button
									variant="ghost"
									size="sm"
									className="h-6 w-6 p-0"
									onClick={() => handleCopyApiKey(user.apiKey, user.id)}
								>
									<Copy
										className={`h-4 w-4 ${copiedId === user.id ? "text-green-500" : ""}`}
									/>
								</Button>
							</div>
						</div>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
