import { api } from "@/lib/api";
import { useQuery } from "@tanstack/react-query";

export const MARKET_RESOURCES_KEY = "marketResources";

export const useMarketResources = () => {
	return useQuery({
		queryKey: [MARKET_RESOURCES_KEY],
		queryFn: async () => {
			const res = await api.market.resources.get();
			return res.data;
		},
		staleTime: 1000 * 60 * 5,
	});
};
