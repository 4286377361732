import { useState, useEffect } from "react";
import { api } from "@/lib/api";
import { AdminUserGrid } from "@/components/adminUserGrid";
import { AdminUserForm } from "@/components/adminUserForm";
import { toast } from "sonner";
import { createFileRoute } from "@tanstack/react-router";
import { useDialogContext } from "@/contexts/dialogContext";

export const Route = createFileRoute("/userDashboard")({
	component: userDashboard,
});

function userDashboard() {
	const [users, setUsers] = useState([]);
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const { openDialog } = useDialogContext();

	const verifyPassword = async (password: string) => {
		try {
			const response = await api.users.get({
				headers: {
					"x-password": password,
				},
			});
			if (response.data.code) {
				return false;
			}
			localStorage.setItem("admin-password", password);
			setIsAuthorized(true);
			setUsers(response.data);
			return true;
		} catch (error) {
			return false;
		} finally {
			setIsLoading(false);
		}
	};

	const promptForPassword = () => {
		openDialog(
			"password",
			{
				title: "Admin Authentication Required",
				description: "Please enter the administrator password to continue",
				confirmButtonText: "Verify",
			},
			async (password) => {
				const isValid = await verifyPassword(password);
				if (!isValid) {
					toast.error("Invalid password");
				}
			},
		);
	};

	useEffect(() => {
		const checkAuth = async () => {
			const savedPassword = localStorage.getItem("admin-password");
			if (!savedPassword) {
				setIsLoading(false);
				promptForPassword();
				return;
			}

			const isValid = await verifyPassword(savedPassword);
			if (!isValid) {
				localStorage.removeItem("admin-password");
				promptForPassword();
			}
		};

		checkAuth();
	}, []);

	const handleUserCreated = async (newUser) => {
		const adminPassword = localStorage.getItem("admin-password");
		if (adminPassword) {
			await verifyPassword(adminPassword);
		}
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (!isAuthorized) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4">
			<AdminUserForm onUserCreated={handleUserCreated} />
			<AdminUserGrid users={users} />
		</div>
	);
}

export default userDashboard;
